<template>
  <b-avatar
    :src="company.logo"
    :text=" avatarText(company.name)"
    :size="size"
    rounded
    @click="$refs.refInputEl.click()"
  />
</template>
<script>
export default {
  name: 'CompanyLogo',
  props: {
    company: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'auto',
    },
  },
  methods: {
    avatarText(value) {
      if (!value) {
        return ''
      }
      const words = value.split(' ')
      const nameArray = [words[0]]
      if (words.length > 0) {
        nameArray.push(words[words.length - 1])
      }

      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <b-modal
    id="token-modal"
    ref="token-modal"
    cancel-variant="outline-secondary"
    scrollable
    no-close-on-backdrop
    no-close-on-esc
    centered
    :ok-only="true"
    ok-title="Fechar"
    cancel-title="Fechar"
    size="lg"
    style="height: 80%"
    :title="title"
    @ok="onCancelToken"
    @close="onCancelToken"
  >
    <b-card>
      <h4>Adicionar Token</h4>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormTokenObserver"
      >
        <b-form
          ref="formData"
          class=""
          @submit.prevent="handleSubmit(onSaveToken)"
          @reset.prevent="onCancelToken"
        >
          <b-row>
            <b-col
              sm="6"
            >
              <i2-form-input
                v-model="modelToken.tokenName"
                rules="required"
                label="Nome para Token"
                placeholder="Nome para Token"
                name="name"
              />
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <br>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                  class="mr-2"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                  Gerar Token
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <Grid
      :fields="fields"
      :items="items"
      label="CompanyTokens"
      new-button-label="new"
      edit-button-label=""
      :show-search="false"
      @onChangePage="getTokens()"
    >

      <template
        #cell(name)="data"
      >
        {{ data.item.name }}
      </template>
      <template #cell(token)="data">
        {{ data.item.token }}
      </template>

      <template
        #cell(action)="data"
      >
        <i2-icon-button
          icon="CopyIcon"
          variant="outline-primary"
          class="mr-1"
          @click="doCopy(data.item.token)"
        />
        <i2-icon-button
          fa-icon="fa-trash"
          variant="outline-danger"
          class="mr-1"
          @click="onDelete(data.item)"
        />
      </template>
    </Grid>
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'ClientTokenModal',
  components: {
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isActiveToken: {
      type: Boolean,
      required: true,
    },
    clientUuid: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      search: {
        search: '',
      },
      modelToken: {
        idToken: '',
        tokenName: '',
        token: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-20',
        },
        {
          key: 'token',
          label: 'Token',
          class: 'fixed-width-300px',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20',
        },
      ],
      currentList: '',
      items: [],
      currentTokenCompany: {},
      isActivated: false,
    }
  },
  watch: {
    isActiveToken(value) {
      if (value) {
        this.getTokens()
      }
    },
  },
  methods: {
    doCopy(token) {
      navigator.clipboard.writeText(token)
    },
    async getTokens(page) {
      this.modelToken = {
        idToken: '',
        tokenName: '',
        token: '',
      }

      this.items = await this.$search.get(
        `clients/${this.clientUuid}/tokens`,
        page,
        this.search,
        '',
      )
    },
    onCancelToken() {
      this.isActivated = false
      this.currentList = ''
      this.$emit('onClose', true)
    },
    onSaveToken() {
      this.$refs.refFormTokenObserver.validate()
        .then(async success => {
          if (!success) {
            return
          }
          const data = { name: this.modelToken.tokenName }
          const route = `clients/${this.clientUuid}/token-create`
          const response = await this.$http.post(route, data)

          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          await this.getTokens()
        })
    },
    onDelete(item) {
      const route = `clients/token-remove/${item.id}`
      this.$apiDelete.execute(
        route,
        'Confirma a exclusão do token da lista',
        'Excluir token',
        () => {
          this.$notify.success(`Token ${item.name} removido da lista com sucesso.`)
          this.getTokens()
          this.$emit('onDelete')
        },
      )
    },
  },
}
</script>

<style>
.fixed-width-300px {
  max-width: 300px !important;
}
</style>
